<template>
  <div class="home">
    <home-hero></home-hero>
    <home-blocks></home-blocks>
    <home-services :services="services"></home-services>
  </div>
</template>

<script>
// @ is an alias to /src
import homeHero from "@/components/home-hero"
import homeBlocks from "@/components/home-blocks"
import homeServices from "@/components/service-block/services"
import homeMission from "@/components/home-mission"
import homePartners from "@/components/home-partners"
export default {
  name: 'home',
  components: {
    homeHero,
    homeBlocks,
    homeServices,
    homeMission,
    homePartners
  },
  data() {
    return {
      services: [
        {
          title: "Custom App Development",
          desc: "We believe that software solutions that are specifically tailored to your business can dramatically streamline workflow and eliminate the associated human errors.",
          icon: "custom.svg"
        },
        {
          title: "Custom Web Development",
          desc:  "Our custom web development solutions accelerate workflows, boost revenues, and optimize business operations from concept-to-code and development-to-deployment.",
          icon: "web.svg"
        },
        {
          title: "Implementation & Deployment",
          desc: "We devise an in-depth, comprehensive software implementation & deployment plan, assessing your needs to deliver enhanced technologies to end-users.",
          icon: "deploy.svg"
        },
        {
          title: "API Development",
          desc: "We develop reliable, well-documented, and easy-to-consume APIs that enable flexible integrations and customization of existing software products.",
          icon: "api.svg"
        },
        {
          title: "System Integrations",
          desc: "Our software integration engineers adopt new technologies and processes to overcome challenges relating to everything from architectural design to testing to execution.",
          icon: "integration.svg"
        },
        {
          title: "Application Maintenance",
          desc: "Our app maintenance & modernization services are designed to ensure the scalability, performance, and sustainability of your entire software infrastructure.",
          icon: "maintenance.svg"
        },
        {
          title: "Autodesk Product Expertise",
          desc: "Seamlessly integrate Autodesk AutoCAD with your existing business workflows to enable architects, engineers, and construction professionals to draft, annotate, 2D designs.",
          icon: "cad.svg"
        }
      ]
    }
  }
}
</script>
