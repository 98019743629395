import Home from '../views/Home.vue';
//import About from '../views/About.vue';
//import Services from '../views/Services.vue';
//import Contact from '../views/Contact.vue';

export const routes = [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'Plettware LLC'
      }
    },
    //{
    //  path: '/about',
    //  name: 'about',
    //  component: About
    //},
    //{
    //  path: '/services',
    //  name: 'services',
    //  component: Services
    //},
    //{
    //  path: '/contact',
    //  name: 'contact',
    //  component: Contact
    //}
  ]
