import Vue from 'vue'
import VueRouter from 'vue-router'
import {routes} from './routes';

Vue.use(VueRouter)


const router = new VueRouter({
  routes: routes 
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Plettware LLC'
  next();
});

export default router
